var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"BookingEmail",attrs:{"id":"EmailModal","title":"Email","size":"xl","no-enforce-focus":true,"centered":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.SendEmail()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Send")])])],1)]},proxy:true}])},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('validation-observer',{ref:"emailrules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Subject","rules":"max:500|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Subject:")])])]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"Subject"},model:{value:(_vm.Subject),callback:function ($$v) {_vm.Subject=$$v},expression:"Subject"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"To","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("To:")])])]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"To"},model:{value:(_vm.To),callback:function ($$v) {_vm.To=$$v},expression:"To"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CC","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("CC:")])])]),_c('b-form-input',{attrs:{"state":errors.length > 0 ? false:null,"placeholder":"CC"},model:{value:(_vm.CC),callback:function ($$v) {_vm.CC=$$v},expression:"CC"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Body","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Body:")])])]),_c('ckeditor',{attrs:{"config":_vm.config,"editor-url":'https://cdn.ckeditor.com/4.17.1/full/ckeditor.js'},model:{value:(_vm.content),callback:function ($$v) {_vm.content=$$v},expression:"content"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }