<template>
  <div>

    <b-modal
      id="SearchScheduleModal"
      ref="SearchSchedule"
      title="Available Schedule"
      size="xl"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="3">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="Prefered Carrier"
              rules="required"
            >
              <b-form-group
                :state="errors.length > 0 ? false:null"
              >
                <label class="required"><i><b>Prefered Carrier(SCAC):</b></i></label>
                <v-select
                  v-model="BKDetail.Carrier"
                  :options="CarrierOptions"
                  placeholder="Select a SCAC"
                  label="scac"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="POL"
              rules="required"
            >
              <b-form-group
                :state="errors.length > 0 ? false:null"
              >
                <label class="required"><i><b>POL:</b></i></label>
                <v-select
                  v-model="BKDetail.POL"
                  :options="POLPODOptions"
                  label="location"
                  placeholder="Select a Location"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group>
            <validation-provider
              #default="{ errors }"
              name="POD"
              rules="required"
            >
              <b-form-group
                :state="errors.length > 0 ? false:null"
              >
                <label class="required"><i><b>POD:</b></i></label>
                <v-select
                  v-model="BKDetail.POD"
                  :options="POLPODOptions"
                  label="location"
                  placeholder="Select a Location"
                />
                <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col md="3">
          <br>
          <b-button
            v-b-tooltip.hover="'Search schedule'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            :disabled="(CheckNull(BKDetail.Carrier) && CheckNull(BKDetail.POL) && CheckNull(BKDetail.POD)) ? false : true"
            @click="SearchSchedule()"
          ><font-awesome-icon
            :icon="['fas', 'search']"
            class="mr-50"
            size="lg"
          />
          </b-button></b-col>
      </b-row>
      <AgGrid
        ref="BookingScheduleSearchGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      />
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveSelectSchedules()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  name: 'BookingSailingSchedule',
  components: {
    AgGrid,
  },
  props: {
    bookingId: { type: String, default: '' },
  },
  data() {
    return {
      required,
      aggrid: [],
      Permission: {},
      Role: '',
      BKDetail: {
        POD: '',
        PODID: '',
        POL: '',
        POLID: '',
        Carrier: '',
        CarrierId: 0,
      },
      CarrierOptions: [],
      POLPODOptions: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.aggrid = this.InitGridInstance('Booking.SailingSchedule.Search')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: '',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 60,
        minWidth: 60,
        maxWidth: 60,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        enableRowGroup: false,
        enablePivot: false,
        checkboxSelection: true,
      },
      {
        headerName: 'S/C Holder', field: 'contractType', minWidth: 100,
      },
      {
        headerName: 'Service', field: 'service', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Transit Time', field: 'transitTime', minWidth: 85,
      },
      {
        headerName: 'POL', field: 'polName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'CY Cutoff',
        field: 'cyCutoffDate',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'POD', field: 'podName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'POD ETA',
        field: 'podeta',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Final Dest', field: 'finalDestName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Final Dest ETA',
        field: 'finalDestETA',
        minWidth: 150,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Terimal', field: 'terminalLocationName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Feeder Vsl',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'feederVesselName',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: params => `${params.data.feederVesselName || ''} | ${params.data.feederVoyage || ''}`,
            },
          },
          {
            headerName: 'ETD',
            field: 'feederETD',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'ETA',
            field: 'feederETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'VIA POL', field: 'feederVIAPOLName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'VIA POD', field: 'feederVIAPODName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Mother Vsl',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'motherVesselName',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: params => `${params.data.motherVesselName || ''} | ${params.data.motherVoyage || ''}`,
            },
          },
          {
            headerName: 'ETD',
            field: 'motherETD',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'ETA',
            field: 'motherETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'VIA POL', field: 'motherVIAPOLName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'VIA POD', field: 'motherVIAPODName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
        ],
      },

    ]
    this.aggrid.rowData = []
    this.aggrid.rowSelection = 'multiple'

    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.BookingScheduleSearchGrid.saveState('Booking.SailingSchedule.Search') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.BookingScheduleSearchGrid.saveState('Booking.SailingSchedule.Search') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.BookingScheduleSearchGrid.saveState('Booking.SailingSchedule.Search') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.BookingScheduleSearchGrid.saveState('Booking.SailingSchedule.Search') }

    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadPOLPODList(), this.LoadCarrierList(), this.LoadBookingDetail()]).then(() => {
        this.LoadingEnd()
      })
    },
    LoadCarrierList() {
      return axios.get('/booking/carrier')
        .then(rowData => {
          this.CarrierOptions = rowData.data
        })
    },
    LoadPOLPODList() {
      // Loading Customer List
      return axios.get('/booking/codes/polpod')
        .then(rowData => {
          this.POLPODOptions = rowData.data
        })
    },
    LoadBookingDetail() {
      return axios.get(`/booking/${this.bookingId}/detail`)
        .then(resp => {
          const response = resp.data
          const respdata = response
          this.BKDetail.CarrierId = respdata.carrier
          this.BKDetail.PODID = respdata.pod
          this.BKDetail.POLID = respdata.pol
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
        })
    },
    SelectNewSchedule() {
      this.BKDetail.Carrier = this.CarrierOptions.find(option => option.id === this.BKDetail.CarrierId)
      this.BKDetail.POL = this.POLPODOptions.find(option => option.id === this.BKDetail.POLID)
      this.BKDetail.POD = this.POLPODOptions.find(option => option.id === this.BKDetail.PODID)
      this.aggrid.rowData = []
      this.$refs.SearchSchedule.show()
    },
    RefreshBooking() {
      this.$emit('RefreshBooking')
    },
    UpdateCarrierPOLPOD() {
      axios.put(`/booking/${this.bookingId}/detail/CarrierPOLPOD/?pod=${this.BKDetail.POD.id}&pol=${this.BKDetail.POL.id}&carrier=${this.BKDetail.Carrier.id}`)
    },
    SearchSchedule() {
      this.LoadingStart()
      this.UpdateCarrierPOLPOD()
      axios.get(`/booking/carrier/${this.BKDetail.Carrier.id}/sailingschedule/?pod=${this.BKDetail.POD.id}&pol=${this.BKDetail.POL.id}`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.BookingScheduleSearchGrid.ResetColumns()
          this.LoadingEnd()
        })
    },
    SaveSelectSchedules() {
      this.LoadingStart()
      const selectedNodes = this.aggrid.gridOptions.api.getSelectedNodes()
      const selectedData = selectedNodes.map(node => node.data.id)
      axios.post(`/booking/${this.bookingId}/schedules`, selectedData).then(() => {
        this.LoadingEnd()
        this.DisplaySuccess('Schedules selected.').then(() => {
          this.$refs.SearchSchedule.hide()
          this.$emit('LoadScheduleList')
          this.RefreshBooking()
        })
      }).catch(e => {
        this.LoadingEnd()
        this.DisplayErrorRemove('Schedules cannot selected.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
      })
    },
  },
}
</script>
