<template>
  <div>

    <b-modal
      id="EmailModal"
      ref="BookingEmail"
      title="Email"
      size="xl"
      :no-enforce-focus="true"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-col md="12">
          <validation-observer
            ref="emailrules"
            tag="form"
          >
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Subject"
                    rules="max:500|required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Subject:</b></i></label>
                      <b-form-input
                        v-model="Subject"
                        :state="errors.length > 0 ? false:null"
                        placeholder="Subject"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="To"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>To:</b></i></label>
                      <b-form-input
                        v-model="To"
                        :state="errors.length > 0 ? false:null"
                        placeholder="To"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="CC"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>CC:</b></i></label>
                      <b-form-input
                        v-model="CC"
                        :state="errors.length > 0 ? false:null"
                        placeholder="CC"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="12">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Body"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Body:</b></i></label>
                      <ckeditor
                        v-model="content"
                        :config="config"
                        :editor-url="'https://cdn.ckeditor.com/4.17.1/full/ckeditor.js'"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
          </validation-observer>
        </b-col>
      </b-row>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SendEmail()"
          >
            <span class="align-middle">Send</span></b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'

export default {
  components: {
  },
  props: {
    bookingId: { type: String, default: '' },
  },
  data() {
    return {
      required,
      content: '',
      config: {
        height: '40em',
        toolbar: [
          ['Styles', 'Format'],
          ['Bold', 'Italic', 'Strike', 'Underline'],
          ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'],
          ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'],
          ['Image', 'Table', 'HorizontalRule', 'SpecialChar'],
          ['Source'],
        ],
      },
      Permission: {},
      Role: '',
      Subject: '',
      To: '',
      CC: '',
      Email: [],
      BKDetail: {
        POD: '',
        PODID: '',
        POL: '',
        POLID: '',
        Carrier: '',
        CarrierId: 0,
      },
      CarrierOptions: [],
      POLPODOptions: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      this.Role = this.GetUserRole()
      // Get permission
      this.Permission = this.GetUserPermission('Booking')
    },
    LoadBookingDetail() {
      try {
        return axios.get(`/booking/${this.bookingId}/email/get`)
          .then(resp => {
            const response = resp.data
            this.Email = response
          })
      } catch (e) {
        this.Email = ''
        return true
      }
    },
    OpenEmail() {
      this.LoadingStart()
      this.LoadBookingDetail().then(() => {
        this.LoadingEnd()
        this.$refs.BookingEmail.show()
        this.CC = this.$store.state.user.AppActiveUser.Email
        this.Subject = `${this.Email.confirmation.consignee} / New Ocean Booking: ${this.Email.confirmation.hbl} / ${this.Email.confirmation.po} / ${this.Email.confirmation.pol} / ${this.Email.confirmation.pod}`
        this.content = `<p>Hi,<br>
      Please see below for the Booking Confirmation and carrier options.</p>

    <table border="1">
      <tbody>
        <tr>
          <td
            colspan="2"
            style="background-color:#66ccff; text-align:center"
          >
            <strong>Booking Confirmation</strong>
          </td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>No. of Consolidations</strong>
          </td>
          <td>1</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>HBL#</strong>
          </td>
          <td>${this.Email.confirmation.hbl}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Shipper</strong>
          </td>
          <td>${this.Email.confirmation.shipper}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Consignee</strong>
          </td>
          <td>${this.Email.confirmation.consignee}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>PO-Item#/Style #</strong>
          </td>
          <td>${this.Email.confirmation.po}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Commodity</strong>
          </td>
          <td>${this.Email.confirmation.commodity}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Place of Receipt</strong>
          </td>
          <td>${this.Email.confirmation.pol}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Place of Loading</strong>
          </td>
          <td>${this.Email.confirmation.pol}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Place of Discharge</strong>
          </td>
          <td>${this.Email.confirmation.pod}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Destination</strong>
          </td>
          <td>${this.Email.confirmation.finalDest}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>PCS</strong>
          </td>
          <td>${this.Email.confirmation.pcs}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>CTNS</strong>
          </td>
          <td>${this.Email.confirmation.ctns}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>KGS</strong>
          </td>
          <td>${this.Email.confirmation.kgs}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>CBM</strong>
          </td>
          <td>${this.Email.confirmation.cbm}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>CTR Size</strong>
          </td>
          <td>${this.Email.confirmation.containerSize}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Cargo Date</strong>
          </td>
          <td>${this.Email.confirmation.cargoReadyDate}</td>
        </tr>
      </tbody>
    </table>
    <p>&nbsp;</p>`

        this.Email.carrierOptions.forEach(e => {
          const Routing = `${e.polName + ((e.feederVIAPODName === '') ? `-${e.feederVIAPODName}` : '') + ((e.motherVIAPODName === '') ? `-${e.motherVIAPODName}` : '')}-${e.podName}`
          this.content += `
    <table border="1">
      <tbody>
        <tr>
          <td
            colspan="2"
            style="background-color:#66ccff; text-align:center"
          >
            <strong>Carrier Option</strong>
          </td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>HBL#</strong>
          </td>
          <td>${this.Email.confirmation.hbl}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Carrier</strong>
          </td>
          <td>${this.Email.confirmation.scac}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>POL</strong>
          </td>
          <td>${e.polName}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>POD</strong>
          </td>
          <td>${e.podName}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Routing (Via)</strong>
          </td>
          <td>${Routing}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong># of CNTR / CNTR Size</strong>
          </td>
          <td>${this.Email.confirmation.containerSize}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>SI Cut-Off</strong>
          </td>
          <td>${e.siCutoffDate}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>CY Cut-Off</strong>
          </td>
          <td>${e.cyCutoffDate}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Feeder Vsl Name/Voyage</strong>
          </td>
          <td>${`${e.feederVesselName}/${e.feederVoyage}`}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Feeder Vsl ETD POL</strong>
          </td>
          <td>${e.feederETD}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Feeder Vsl ETA</strong>
          </td>
          <td>${e.feederETA}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>M/V (Direct Vsl) Name/Voy</strong>
          </td>
          <td>${`${e.motherVesselName}/${e.motherVoyage}`}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>M/V ETD</strong>
          </td>
          <td>${e.motherETD}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>M/V ETA POD</strong>
          </td>
          <td>${e.motherETA}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Service Contact Type</strong>
          </td>
          <td>${this.Email.confirmation.serviceContactType}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Ocean Freight Rate (Port - Port)</strong>
          </td>
          <td>${e.remarks}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Ocean Freight Rate Valid From/Expire Date</strong>
          </td>
          <td></td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>S/C Service Holder</strong>
          </td>
          <td>${e.contractType}</td>
        </tr>
        <tr>
          <td style="background-color:#dddddd">
            <strong>Destination Detention Free Time (Days)</strong>
          </td>
          <td>${this.Email.confirmation.detentionDay}</td>
        </tr>
      </tbody>
    </table>`
        })

        this.content += `<p>Thank you,<br>${`${this.$store.state.user.AppActiveUser.FirstName} ${this.$store.state.user.AppActiveUser.LastName}`}</p>`
      })
    },
    SendEmail() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, send it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.$refs.emailrules.validate().then(c => {
            if (c) {
              this.LoadingStart()
              const email = {
                Subject: this.Subject,
                To: this.To,
                CC: this.CC,
                Content: this.content,
              }
              axios.post(`/booking/${this.bookingId}/email/send`, email).then(() => {
                this.LoadingEnd()
                this.DisplaySuccess('Email has been sent.')
                  .then(() => {
                    this.$refs.BookingEmail.hide()
                  })
              }).catch(e => {
                this.LoadingEnd()
                this.DisplayErrorRemove('Email cannot be sent.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
              })
            } else {
              this.DisplayError('Cannot send email.', 'Please check data requirements.')
            }
          })
        }
      })
    },
  },
}
</script>
