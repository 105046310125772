<template>
  <div>
    <b-card>
      <AgGrid
        ref="BookingContainerGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="(!BKDetail.IsArchive && ((BKDetail.IsNew || BKDetail.IsDenied || BKDetail.IsReopen) ) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Add new containers'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="CreateNewContainer()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Add Container</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <b-modal
      id="CreateContainerModal"
      ref="CreateContainer"
      title="New Container"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="NewContainerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Container Type:</b></i></label>
                  <v-select
                    v-model="container.ContainerSize"
                    :options="ContainerTypeOption"
                    label="text"
                    placeholder="Select the type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Qty"
                rules="required|min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b># of Containers:</b></i></label>
                  <b-form-input
                    v-model="container.Qty"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CBM"
                rules="required|min_value:0"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Total CBM:</b></i></label>
                  <b-form-input
                    v-model="container.CBM"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Weight (KG)"
                rules="required|min_value:0"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Total Weight (KG):</b></i></label>
                  <b-form-input
                    v-model="container.Weight"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Pallet"
                rules="min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Total Pallet:</b></i></label>
                  <b-form-input
                    v-model="container.Pallet"
                    type="number"
                    min="0"
                    step="1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CTN"
                rules="min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Total CTN:</b></i></label>
                  <b-form-input
                    v-model="container.CTN"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="PCS"
                rules="min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Total PCS:</b></i></label>
                  <b-form-input
                    v-model="container.PCS"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="Role != 'customer'"
            md="6"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ocean Freight Cost"
                rules="min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Ocean Freight Cost:</b></i></label>
                  <b-form-input
                    v-model="container.OceanFreightCost"
                    type="number"
                    min="0"
                    step="0.01"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ocean Freight Sell Rate"
                rules="min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Ocean Freight Sell Rate:</b></i></label>
                  <b-form-input
                    v-model="container.OceanFreightSellRate"
                    type="number"
                    min="0"
                    step="0.01"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="Role === 'manager' || Role === 'company admin'"
            md="6"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Origin Sell Rate"
                rules="min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Origin Sell Rate:</b></i></label>
                  <b-form-input
                    v-model="container.OriginSellRate"
                    type="number"
                    min="0"
                    step="0.01"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SaveNewContainer()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

    <b-modal
      id="UpdateContainerModal"
      ref="UpdateContainer"
      title="Update Container"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="UpdateContainerRules"
        tag="form"
      >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Container Type"
                rules="required"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Container Type:</b></i></label>
                  <v-select
                    v-model="container.ContainerSize"
                    :options="ContainerTypeOption"
                    label="text"
                    placeholder="Select the type"
                  />
                  <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                    {{ errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Qty"
                rules="required|min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b># of Containers:</b></i></label>
                  <b-form-input
                    v-model="container.Qty"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CBM"
                rules="required|min_value:0"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Total CBM:</b></i></label>
                  <b-form-input
                    v-model="container.CBM"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Weight (KG)"
                rules="required|min_value:0"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label class="required"><i><b>Total Weight (KG):</b></i></label>
                  <b-form-input
                    v-model="container.Weight"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Pallet"
                rules="min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Total Pallet:</b></i></label>
                  <b-form-input
                    v-model="container.Pallet"
                    type="number"
                    min="0"
                    step="1"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="CTN"
                rules="min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Total CTN:</b></i></label>
                  <b-form-input
                    v-model="container.CTN"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="PCS"
                rules="min_value:0|integer"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Total PCS:</b></i></label>
                  <b-form-input
                    v-model="container.PCS"
                    type="number"
                    min="0"
                    step="0.001"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="Role != 'customer'"
            md="6"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ocean Freight Cost"
                rules="min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Ocean Freight Cost:</b></i></label>
                  <b-form-input
                    v-model="container.OceanFreightCost"
                    type="number"
                    min="0"
                    step="0.01"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Ocean Freight Sell Rate"
                rules="min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Ocean Freight Sell Rate:</b></i></label>
                  <b-form-input
                    v-model="container.OceanFreightSellRate"
                    type="number"
                    min="0"
                    step="0.01"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>

          <b-col
            v-if="Role === 'manager' || Role === 'company admin'"
            md="6"
          >
            <b-form-group>
              <validation-provider
                #default="{ errors }"
                name="Origin Sell Rate"
                rules="min_value:0|positive"
              >
                <b-form-group
                  :state="errors.length > 0 ? false:null"
                >
                  <label><i><b>Origin Sell Rate:</b></i></label>
                  <b-form-input
                    v-model="container.OriginSellRate"
                    type="number"
                    min="0"
                    step="0.01"
                    :state="errors.length > 0 ? false:null"
                    placeholder="0"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UpdateContainer()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Booking/BookingContainerBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'

export default {
  name: 'BookingContainer',
  components: {
    AgGrid,
  },
  props: {
    bookingId: { type: String, default: '' },
  },
  data() {
    return {
      required,
      TempLineId: '',
      aggrid: [],
      ContainerTypeOption: [],
      container: {
        ContainerId: '',
        ContainerSize: '',
        Qty: 0,
        CBM: 0,
        Weight: 0,
        Pallet: 0,
        PCS: 0,
        CTN: 0,
        OceanFreightCost: 0,
        OceanFreightSellRate: 0,
        OriginSellRate: 0,
      },
      Role: '',
      Permission: [],
      BKDetail: {
        IsNew: false,
        IsCancel: false,
        IsReopen: false,
        IsPendingReview: false,
        IsApproved: false,
        IsDenied: false,
        IsAttached: false,
        IsArchive: false,
        Weight: 0,
        Pallet: 0,
        CBM: 0,
        PCS: 0,
        CTN: 0,
      },

    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    // Get user role
    this.Role = this.GetUserRole()

    this.aggrid = this.InitGridInstance('BookingDetailContainer')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 120,
        minWidth: 120,
        maxWidth: 120,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      { headerName: 'Size', field: 'containerSize', minWidth: 100 },
      { headerName: '# of Containers', field: 'qty', minWidth: 125 },
      { headerName: 'Total CBM', field: 'cbm', minWidth: 100 },
      { headerName: 'Total Weight (KG)', field: 'weight', minWidth: 100 },
      { headerName: 'Pallet', field: 'pallet', minWidth: 100 },
      { headerName: 'CTN', field: 'ctn', minWidth: 100 },
      { headerName: 'PCS', field: 'pcs', minWidth: 100 },
      {
        headerName: 'Overweight Ack\'ed?',
        field: 'isOverLimitAck',
        minWidth: 100,
        cellRenderer: params => (params.value ? 'Yes' : 'No'),
      },
    ]

    if (this.Role !== 'customer') this.aggrid.columnDefs.push({ headerName: 'Ocean Freight Cost', field: 'oceanFreightCost', minWidth: 100 })

    this.aggrid.columnDefs.push({ headerName: 'Ocean Freight Sell Rate', field: 'oceanFreightSellRate', minWidth: 100 })
    if (this.Role === 'manager' || this.Role === 'company admin') this.aggrid.columnDefs.push({ headerName: 'Origin Sell Rate', field: 'originSellRate', minWidth: 100 })
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.BookingContainerGrid.saveState('BookingDetailContainer') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.BookingContainerGrid.saveState('BookingDetailContainer') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.BookingContainerGrid.saveState('BookingDetailContainer') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.BookingContainerGrid.saveState('BookingDetailContainer') }

    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }
    this.LoadAll()
  },
  methods: {
    Open(ContainerId) {
      this.LoadContainer(ContainerId).then(() => {
        this.$refs.UpdateContainer.show()
      })
    },
    DoubleClickOpenPopup(params) {
      this.LoadingStart()
      this.LoadContainer(params.data.id).then(() => {
        this.$refs.UpdateContainer.show()
      })
    },
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadContainers(), this.LoadContainerCodes(), this.LoadBookingDetail()])
        .then(() => {
          this.LoadingEnd()
        })
    },
    LoadBookingDetail() {
      return axios.get(`/booking/${this.bookingId}/detail`)
        .then(resp => {
          const response = resp.data
          const respdata = response

          this.BKDetail.IsNew = respdata.isNew
          this.BKDetail.IsCancel = respdata.isCancel
          this.BKDetail.IsReopen = respdata.isReopen
          this.BKDetail.IsPendingReview = respdata.isPendingReview
          this.BKDetail.IsApproved = respdata.isApproved
          this.BKDetail.IsDenied = respdata.isDenied
          this.BKDetail.IsAttached = respdata.isAttached
          this.BKDetail.IsArchive = respdata.isArchive
          this.BKDetail.Weight = respdata.weight
          this.BKDetail.Pallet = respdata.pallet
          this.BKDetail.CBM = respdata.cbm
          this.BKDetail.PCS = respdata.pcs
          this.BKDetail.CTN = respdata.ctn

          // Get permission
          this.Permission = this.GetUserPermission('Booking')
        })
    },
    LoadContainerCodes() {
      // Loading Customer List
      return axios.get('/booking/codes/containersize')
        .then(rowData => {
          this.ContainerTypeOption = rowData.data
        })
    },
    LoadContainers() {
      return axios.get(`/booking/${this.bookingId}/containers`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.BookingContainerGrid.ResetColumns()
        })
    },
    LoadContainer(ContainerId) {
      return axios.get(`/booking/${this.bookingId}/containers/${ContainerId}`).then(resp => {
        const response = resp.data
        const respdata = response
        this.container.ContainerId = respdata.id
        this.container.ContainerSize = this.ContainerTypeOption.find(option => option.containerCode === respdata.containerSize)
        this.container.Qty = respdata.qty
        this.container.CBM = respdata.cbm
        this.container.Weight = respdata.weight
        this.container.Pallet = respdata.pallet
        this.container.PCS = respdata.pcs
        this.container.CTN = respdata.ctn
        this.container.OceanFreightCost = respdata.oceanFreightCost
        this.container.OceanFreightSellRate = respdata.oceanFreightSellRate
        this.container.OriginSellRate = respdata.originSellRate
      }).then(() => {
        this.LoadingEnd()
      })
    },
    CreateNewContainer() {
      this.ResetContainerVariable()
      this.$refs.CreateContainer.show()
    },
    SaveNewContainer() {
      this.$refs.NewContainerRules.validate().then(success => {
        if (success) {
          const BookingContainersModel = {
            ContainerSize: this.container.ContainerSize.containerCode,
            Qty: this.container.Qty,
            CBM: this.container.CBM,
            Weight: this.container.Weight,
            Pallet: this.container.Pallet,
            PCS: this.container.PCS,
            CTN: this.container.CTN,
            OceanFreightCost: this.container.OceanFreightCost,
            OceanFreightSellRate: this.container.OceanFreightSellRate,
            OriginSellRate: this.container.OriginSellRate,
          }
          const weightlimit = this.container.Qty * this.container.ContainerSize.weightLimit
          const currentweight = this.container.Weight * 2.20462 // this.container.Qty *

          if ((weightlimit < currentweight) && this.container.ContainerSize.containerCode !== 'LCL') {
            this.$swal({
              title: 'Overweight!',
              text: 'The total weight is over the legal limit, are you sure you want to continue?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Yes, proceed!',
            }).then(() => {
              this.LoadingStart()

              axios.post(`/booking/${this.bookingId}/containers`, BookingContainersModel).then(() => {
                this.LoadingEnd()
                this.$refs.CreateContainer.hide()
                this.DisplaySuccess('Container data has been saved.').then(() => {
                  this.LoadContainers()
                  this.ResetContainerVariable()
                })
              })
                .catch(e => {
                  this.LoadingEnd()
                  this.$refs.CreateContainer.hide()
                  this.DisplayError('New Container cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
                })
            })
          } else if ((weightlimit > currentweight) || this.container.ContainerSize.containerCode === 'LCL') {
            this.LoadingStart()

            axios.post(`/booking/${this.bookingId}/containers`, BookingContainersModel).then(() => {
              this.LoadingEnd()
              this.$refs.CreateContainer.hide()
              this.DisplaySuccess('Container data has been saved.').then(() => {
                this.LoadContainers()
                this.LoadBookingDetail().then(() => {
                  const returnvalue = {
                    CBM: this.BKDetail.CBM,
                    Weight: this.BKDetail.Weight,
                    Pallet: this.BKDetail.Pallet,
                    CTN: this.BKDetail.CTN,
                    PCS: this.BKDetail.PCS,
                  }
                  // return to parent
                  this.$emit('LoadChildWeightCBM', returnvalue)
                })
                this.ResetContainerVariable()
              })
            })
              .catch(e => {
                this.LoadingEnd()
                this.$refs.CreateContainer.hide()
                this.DisplayError('New Container cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
              })
          }
        } else {
          this.DisplayError('New Container cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    UpdateContainer() {
      if (this.BKDetail.IsCancel || this.BKDetail.IsArchive || (this.BKDetail.IsPendingReview || this.BKDetail.IsApproved || this.BKDetail.IsAttached)) {
        this.DisplayError('Container cannot be updated.', 'Booking is Pending Reivew/Approved/Attached/Archived/Cancelled.')
      } else if (this.Permission.EditBooking) {
        this.$refs.UpdateContainerRules.validate().then(success => {
          if (success) {
            const BookingDto = {
              UserId: this.$store.state.user.AppActiveUser.Id,
              DBContext: (this.$store.state.user.AppActiveUser.BookingDbContext),
              BookingId: this.bookingId,
              ContainerId: this.container.ContainerId,
            }
            const BookingContainersModel = {
              ContainerSize: this.container.ContainerSize.containerCode,
              Qty: this.container.Qty,
              CBM: this.container.CBM,
              Weight: this.container.Weight,
              Pallet: this.container.Pallet,
              PCS: this.container.PCS,
              CTN: this.container.CTN,
              OceanFreightCost: this.container.OceanFreightCost,
              OceanFreightSellRate: this.container.OceanFreightSellRate,
              OriginSellRate: this.container.OriginSellRate,
            }
            // Calculate weight limit KG = 2.20462 lb
            const weightlimit = this.container.Qty * this.container.ContainerSize.weightLimit
            const currentweight = this.container.Weight * 2.20462 // this.container.Qty *
            if ((weightlimit < currentweight) && this.container.ContainerSize.containerCode !== 'LCL') {
              // overweight
              this.$swal({
                title: 'Overweight!',
                text: 'The total weight is over the legal limit, are you sure you want to continue?',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, proceed!',
              }).then(result => {
                this.LoadingStart()
                if (result.isConfirmed) {
                  axios.put(`/booking/${this.bookingId}/containers/${this.container.ContainerId}/overweight`).then(() => {
                    this.UpdateContainerDetail(BookingDto, BookingContainersModel)
                  })
                } else {
                  this.UpdateContainerDetail(BookingDto, BookingContainersModel)
                }
              })
            } else {
              this.UpdateContainerDetail(BookingDto, BookingContainersModel)
            }
          } else {
            this.DisplayError('Container cannot be updated.', 'Please check data requirements.')
          }
        })
      } else {
        this.DisplayError('Container cannot be updated.', 'You are not authorized to perform this action.')
      }
    },
    UpdateContainerDetail(BookingDto, BookingContainersModel) {
      axios.put(`/booking/${BookingDto.BookingId}/containers/${BookingDto.ContainerId}`, BookingContainersModel).then(() => {
        this.LoadingEnd()
        this.$refs.UpdateContainer.hide()
        this.DisplaySuccess('Container data has been updated.').then(() => {
          this.LoadContainers()
          this.LoadBookingDetail().then(() => {
            const returnvalue = {
              CBM: this.BKDetail.CBM,
              Weight: this.BKDetail.Weight,
              Pallet: this.BKDetail.Pallet,
              CTN: this.BKDetail.CTN,
              PCS: this.BKDetail.PCS,
            }
            // return to parent
            this.$emit('LoadChildWeightCBM', returnvalue)
          })
        })
      })
        .catch(e => {
          this.LoadingEnd()
          this.$refs.UpdateContainer.hide()
          this.DisplayError('Container cannot be updated.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
        })
    },
    Delete(containerId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/booking/${this.bookingId}/containers/${containerId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Container has been removed.')
              .then(() => {
                // redirect
                this.LoadContainers()
                this.LoadBookingDetail().then(() => {
                  const returnvalue = {
                    CBM: this.BKDetail.CBM,
                    Weight: this.BKDetail.Weight,
                    Pallet: this.BKDetail.Pallet,
                    CTN: this.BKDetail.CTN,
                    PCS: this.BKDetail.PCS,
                  }
                  // return to parent
                  this.$emit('LoadChildWeightCBM', returnvalue)
                })
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Container cannot be removed.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ResetContainerVariable() {
      this.container.ContainerId = ''
      this.container.ContainerSize = ''
      this.container.Qty = 0
      this.container.CBM = 0
      this.container.Weight = 0
      this.container.Pallet = 0
      this.container.PCS = 0
      this.container.CTN = 0
      this.container.OceanFreightCost = 0
      this.container.OceanFreightSellRate = 0
    },
  },
}
</script>
