<template>
  <div>
    <b-card>
      <b-row>
        <b-col md="12">
          <app-timeline>
            <app-timeline-item
              v-for="(data, i) in timelineData"
              :key="i"
              :title="data.title"
              :subtitle="data.description"
              :icon="data.icon"
              :time="data.time"
              :color="data.color"
              :added-by="data.addedBy"
            />

          </app-timeline>
        </b-col>
      </b-row>
    </b-card>

  </div>
</template>

<script>
import axios from '@axios'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'

export default {
  name: 'BookingDetailTimeline',
  components: {
    AppTimeline,
    // AppTimelineItem,
  },
  props: {
    bookingId: { type: String, default: '' },
  },
  data() {
    return {
      item: [],
      timelineData: [],
    }
  },
  beforeMount() {
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadTime()]).then(() => {
        this.LoadingEnd()
      })
    },
    LoadTime() {
      return axios.get(`/booking/${this.bookingId}/timeline`).then(rowData => {
        this.timelineData = rowData.data
      })
    },
  },
}

</script>
