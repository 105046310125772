<template>
  <div>
    <b-card>
      <AgGrid
        ref="BookingScheduleGrid"
        :grid="aggrid"
        @LoadAll="LoadAll"
      >
        <template v-slot:lftbtn>
          <b-button
            v-if="(!BKDetail.IsArchive && !BKDetail.IsPendingReview && !BKDetail.IsApproved && !BKDetail.IsAttached && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Add new schedule'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SelectNewSchedule()"
          ><font-awesome-icon
             :icon="['far', 'plus-square']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Add Schedule</span></b-button>
        </template>

      </AgGrid>
    </b-card>

    <SailingScheduleSearch
      ref="BookingScheduleSearch"
      :booking-id="bookingId"
      @LoadScheduleList="LoadScheduleList"
      @RefreshBooking="RefreshBooking"
    />

    <b-modal
      id="RemarkModal"
      ref="ScheduleRemark"
      title="Remark"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <validation-observer
        ref="ScheduleRemarkRules"
        tag="form"
      >
        <b-row>
          <b-col md="12">
            <b-form-textarea
              v-model="Remark"
              placeholder="Remarks"
              rows="3"
              max-rows="6"
            />
          </b-col>
        </b-row>
      </validation-observer>
      <template #modal-footer>
        <div>
          <b-button
            v-if="(!BKDetail.IsArchive && (!BKDetail.IsCancel && !BKDetail.IsPendingReview && !BKDetail.IsApproved && !BKDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="UpdateRemark()"
          >
            <span class="align-middle">Save</span></b-button>
        </div>
      </template>
    </b-modal>

  </div>
</template>

<script>
import axios from '@axios'

import { required } from '@validations'
import ViewBtnRenderer from '@core/layouts/ViewBtnRenderers/Booking/BookingScheduleBtnRenderer'
import AgGrid from '@/layouts/components/AgGrid.vue'
import SailingScheduleSearch from './BookingDetailScheduleSearch.vue'

export default {
  name: 'BookingSailingSchedule',
  components: {
    AgGrid,
    SailingScheduleSearch,
  },
  props: {
    bookingId: { type: String, default: '' },
  },
  data() {
    return {
      required,
      aggrid: [],
      searchaggrid: [],
      Permission: {},
      Role: '',
      BKDetail: {
        IsNew: false,
        IsCancel: false,
        IsReopen: false,
        IsPendingReview: false,
        IsApproved: false,
        IsDenied: false,
        IsAttached: false,
        IsArchive: false,
        POD: '',
        POL: '',
        Carrier: '',
        CarrierId: 0,
      },
      ScheduleId: 0,
      Remark: '',
      CarrierOptions: [],
      POLPODOptions: [],
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.aggrid = this.InitGridInstance('Booking.SailingSchedule')
    this.aggrid.columnDefs = [
      {
        headerName: '',
        field: 'id',
        filter: false,
        sortable: false,
        autoHeight: true,
        width: 150,
        minWidth: 150,
        maxWidth: 150,
        suppressSizeToFit: true,
        suppressMovable: true,
        pinned: 'left',
        lockPinned: true,
        cellRenderer: 'ViewBtnRenderer',
        enableRowGroup: false,
        enablePivot: false,
      },
      {
        headerName: 'Status', field: 'status', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'S/C Holder', field: 'contractType', minWidth: 100,
      },
      {
        headerName: 'Service', field: 'service', minWidth: 85, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Transit Time', field: 'transitTime', minWidth: 85,
      },
      {
        headerName: 'POL', field: 'polName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'CY Cutoff',
        field: 'cyCutoffDate',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'POD', field: 'podName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'POD ETA',
        field: 'podeta',
        minWidth: 120,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Final Dest', field: 'finalDestName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Final Dest ETA',
        field: 'finalDestETA',
        minWidth: 150,
        filter: 'agDateColumnFilter',
        comparator: this.dateComparator,
        filterParams: this.$filterParams,
      },
      {
        headerName: 'Terimal', field: 'terminalLocationName', minWidth: 100, filter: 'agSetColumnFilter',
      },
      {
        headerName: 'Feeder Vsl',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'feederVesselName',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: params => `${params.data.feederVesselName || ''} | ${params.data.feederVoyage || ''}`,
            },
          },
          {
            headerName: 'ETD',
            field: 'feederETD',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'ETA',
            field: 'feederETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'VIA POL', field: 'feederVIAPOLName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'VIA POD', field: 'feederVIAPODName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Mother Vsl',
        marryChildren: true,
        children: [
          {
            headerName: 'Vessel',
            field: 'motherVesselName',
            autoHeight: true,
            wrapText: true,
            minWidth: 220,
            enableRowGroup: false,
            enablePivot: false,
            cellRenderer: 'agGroupCellRenderer',
            cellRendererParams: {
              innerRenderer: params => `${params.data.motherVesselName || ''} | ${params.data.motherVoyage || ''}`,
            },
          },
          {
            headerName: 'ETD',
            field: 'motherETD',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'ETA',
            field: 'motherETA',
            minWidth: 80,
            filter: 'agDateColumnFilter',
            comparator: this.dateComparator,
            filterParams: this.$filterParams,
          },
          {
            headerName: 'VIA POL', field: 'motherVIAPOLName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
          {
            headerName: 'VIA POD', field: 'motherVIAPODName', columnGroupShow: 'open', minWidth: 100, filter: 'agSetColumnFilter',
          },
        ],
      },
      {
        headerName: 'Edit Log',
        marryChildren: true,
        children: [
          {
            headerName: 'Approved By', field: 'approvedBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Approved On',
            field: 'approvedOn',
            maxWidth: 158,
            minWidth: 100,
            enableRowGroup: false,
            enablePivot: false,
          },
          {
            headerName: 'Denied By', columnGroupShow: 'open', field: 'deniedBy', maxWidth: 134, minWidth: 100,
          },
          {
            headerName: 'Denied On',
            columnGroupShow: 'open',
            field: 'deniedOn',
            maxWidth: 158,
            minWidth: 100,
            enableRowGroup: false,
            enablePivot: false,
          },
        ],
      },

    ]
    this.aggrid.gridOptions.onSortChanged = () => { this.$refs.BookingScheduleGrid.saveState('Booking.SailingSchedule') }
    this.aggrid.gridOptions.onColumnResized = () => { this.$refs.BookingScheduleGrid.saveState('Booking.SailingSchedule') }
    this.aggrid.gridOptions.onColumnMoved = () => { this.$refs.BookingScheduleGrid.saveState('Booking.SailingSchedule') }
    this.aggrid.gridOptions.onFilterChanged = () => { this.$refs.BookingScheduleGrid.saveState('Booking.SailingSchedule') }

    this.aggrid.frameworkComponents = {
      ViewBtnRenderer,
    }

    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadPOLPODList(), this.LoadBookingDetail(), this.LoadCarrierList()]).then(() => {
        this.LoadScheduleList()
      })
    },
    LoadCarrierList() {
      return axios.get('/booking/carrier')
        .then(rowData => {
          this.CarrierOptions = rowData.data
        })
    },
    LoadPOLPODList() {
      // Loading Customer List
      return axios.get('/booking/codes/polpod')
        .then(rowData => {
          this.POLPODOptions = rowData.data
        })
    },
    LoadBookingDetail() {
      return axios.get(`/booking/${this.bookingId}/detail`)
        .then(resp => {
          const response = resp.data
          const respdata = response
          this.BKDetail.IsNew = respdata.isNew
          this.BKDetail.IsCancel = respdata.isCancel
          this.BKDetail.IsReopen = respdata.isReopen
          this.BKDetail.IsPendingReview = respdata.isPendingReview
          this.BKDetail.IsApproved = respdata.isApproved
          this.BKDetail.IsDenied = respdata.isDenied
          this.BKDetail.IsAttached = respdata.isAttached
          this.BKDetail.IsArchive = respdata.isArchive
          this.BKDetail.CarrierId = respdata.carrier
          this.BKDetail.POL = respdata.pol
          this.BKDetail.POD = respdata.pod
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
        })
    },
    LoadScheduleList() {
      axios.get(`/booking/${this.bookingId}/schedules`)
        .then(rowData => {
          this.aggrid.rowData = rowData.data
        }).then(() => {
          this.$refs.BookingScheduleGrid.ResetColumns()
        })
    },
    RefreshBooking() {
      this.$emit('RefreshBooking')
    },
    SelectNewSchedule() {
      this.$refs.BookingScheduleSearch.SelectNewSchedule()
    },
    ViewRemark(ScheduleId, Remark) {
      this.Remark = Remark
      this.ScheduleId = ScheduleId
      this.$refs.ScheduleRemark.show()
    },
    UpdateRemark() {
      this.$refs.ScheduleRemarkRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          // Post
          axios.put(`/booking/${this.bookingId}/schedules/${this.ScheduleId}/${this.Remark}`)
            .then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('Remarks have been saved.').then(() => {
                this.$refs.ScheduleRemark.hide()
                this.LoadScheduleList()
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.DisplayError('Remarks cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Remarks cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    Approve(ScheduleId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, approve it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/${this.bookingId}/schedules/${ScheduleId}/approve`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Schedule has been approved.')
              .then(() => {
                //
                this.RefreshBooking()
                this.LoadScheduleList()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Schedule cannot be approved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    Deny(ScheduleId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deny it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/${this.bookingId}/schedules/${ScheduleId}/deny`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Schedule has been denied.')
              .then(() => {
                //
                this.RefreshBooking()
                this.LoadScheduleList()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Schedule cannot be denied.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    Delete(ScheduleId) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/booking/${this.bookingId}/schedules/${ScheduleId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Schedule has been removed.')
              .then(() => {
                //
                this.RefreshBooking()
                this.LoadScheduleList()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Schedule cannot be removed.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
  },
}
</script>
