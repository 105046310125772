var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('AgGrid',{ref:"BookingContainerGrid",attrs:{"grid":_vm.aggrid},on:{"LoadAll":_vm.LoadAll},scopedSlots:_vm._u([{key:"lftbtn",fn:function(){return [((!_vm.BKDetail.IsArchive && ((_vm.BKDetail.IsNew || _vm.BKDetail.IsDenied || _vm.BKDetail.IsReopen) ) && (_vm.Role != 'readonly' && _vm.Role != 'readonly admin') && (_vm.Permission.EditBooking)) || (_vm.Role == 'company admin'))?_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:('Add new containers'),expression:"'Add new containers'",modifiers:{"hover":true}},{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.CreateNewContainer()}}},[_c('font-awesome-icon',{staticClass:"mr-50",attrs:{"icon":['far', 'plus-square'],"size":"lg"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Add Container")])],1):_vm._e()]},proxy:true}])})],1),_c('b-modal',{ref:"CreateContainer",attrs:{"id":"CreateContainerModal","title":"New Container","size":"lg","centered":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.SaveNewContainer()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Save")])])],1)]},proxy:true}])},[_c('validation-observer',{ref:"NewContainerRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Container Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Container Type:")])])]),_c('v-select',{attrs:{"options":_vm.ContainerTypeOption,"label":"text","placeholder":"Select the type"},model:{value:(_vm.container.ContainerSize),callback:function ($$v) {_vm.$set(_vm.container, "ContainerSize", $$v)},expression:"container.ContainerSize"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Qty","rules":"required|min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("# of Containers:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.Qty),callback:function ($$v) {_vm.$set(_vm.container, "Qty", $$v)},expression:"container.Qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CBM","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Total CBM:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.CBM),callback:function ($$v) {_vm.$set(_vm.container, "CBM", $$v)},expression:"container.CBM"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Weight (KG)","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Total Weight (KG):")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.Weight),callback:function ($$v) {_vm.$set(_vm.container, "Weight", $$v)},expression:"container.Weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Pallet","rules":"min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Total Pallet:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"1","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.Pallet),callback:function ($$v) {_vm.$set(_vm.container, "Pallet", $$v)},expression:"container.Pallet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CTN","rules":"min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Total CTN:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.CTN),callback:function ($$v) {_vm.$set(_vm.container, "CTN", $$v)},expression:"container.CTN"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"PCS","rules":"min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Total PCS:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.PCS),callback:function ($$v) {_vm.$set(_vm.container, "PCS", $$v)},expression:"container.PCS"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[(_vm.Role != 'customer')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Ocean Freight Cost","rules":"min_value:0|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Ocean Freight Cost:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.OceanFreightCost),callback:function ($$v) {_vm.$set(_vm.container, "OceanFreightCost", $$v)},expression:"container.OceanFreightCost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3307392890)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Ocean Freight Sell Rate","rules":"min_value:0|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Ocean Freight Sell Rate:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.OceanFreightSellRate),callback:function ($$v) {_vm.$set(_vm.container, "OceanFreightSellRate", $$v)},expression:"container.OceanFreightSellRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(_vm.Role === 'manager' || _vm.Role === 'company admin')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Origin Sell Rate","rules":"min_value:0|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Origin Sell Rate:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.OriginSellRate),callback:function ($$v) {_vm.$set(_vm.container, "OriginSellRate", $$v)},expression:"container.OriginSellRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,851239962)})],1)],1):_vm._e()],1)],1)],1),_c('b-modal',{ref:"UpdateContainer",attrs:{"id":"UpdateContainerModal","title":"Update Container","size":"lg","centered":"","no-close-on-backdrop":""},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('div',[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],staticClass:"mb-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.UpdateContainer()}}},[_c('span',{staticClass:"align-middle"},[_vm._v("Save")])])],1)]},proxy:true}])},[_c('validation-observer',{ref:"UpdateContainerRules",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Container Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Container Type:")])])]),_c('v-select',{attrs:{"options":_vm.ContainerTypeOption,"label":"text","placeholder":"Select the type"},model:{value:(_vm.container.ContainerSize),callback:function ($$v) {_vm.$set(_vm.container, "ContainerSize", $$v)},expression:"container.ContainerSize"}}),_c('b-form-invalid-feedback',{attrs:{"state":errors.length > 0 ? false:null}},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Qty","rules":"required|min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("# of Containers:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.Qty),callback:function ($$v) {_vm.$set(_vm.container, "Qty", $$v)},expression:"container.Qty"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CBM","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Total CBM:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.CBM),callback:function ($$v) {_vm.$set(_vm.container, "CBM", $$v)},expression:"container.CBM"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Weight (KG)","rules":"required|min_value:0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',{staticClass:"required"},[_c('i',[_c('b',[_vm._v("Total Weight (KG):")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.Weight),callback:function ($$v) {_vm.$set(_vm.container, "Weight", $$v)},expression:"container.Weight"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Pallet","rules":"min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Total Pallet:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"1","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.Pallet),callback:function ($$v) {_vm.$set(_vm.container, "Pallet", $$v)},expression:"container.Pallet"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"CTN","rules":"min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Total CTN:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.CTN),callback:function ($$v) {_vm.$set(_vm.container, "CTN", $$v)},expression:"container.CTN"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"PCS","rules":"min_value:0|integer"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Total PCS:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.001","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.PCS),callback:function ($$v) {_vm.$set(_vm.container, "PCS", $$v)},expression:"container.PCS"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1)],1),_c('b-row',[(_vm.Role != 'customer')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Ocean Freight Cost","rules":"min_value:0|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Ocean Freight Cost:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.OceanFreightCost),callback:function ($$v) {_vm.$set(_vm.container, "OceanFreightCost", $$v)},expression:"container.OceanFreightCost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3307392890)})],1)],1):_vm._e(),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Ocean Freight Sell Rate","rules":"min_value:0|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Ocean Freight Sell Rate:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.OceanFreightSellRate),callback:function ($$v) {_vm.$set(_vm.container, "OceanFreightSellRate", $$v)},expression:"container.OceanFreightSellRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),(_vm.Role === 'manager' || _vm.Role === 'company admin')?_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Origin Sell Rate","rules":"min_value:0|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"state":errors.length > 0 ? false:null}},[_c('label',[_c('i',[_c('b',[_vm._v("Origin Sell Rate:")])])]),_c('b-form-input',{attrs:{"type":"number","min":"0","step":"0.01","state":errors.length > 0 ? false:null,"placeholder":"0"},model:{value:(_vm.container.OriginSellRate),callback:function ($$v) {_vm.$set(_vm.container, "OriginSellRate", $$v)},expression:"container.OriginSellRate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,851239962)})],1)],1):_vm._e()],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }