<template>
  <div>
    <b-card>
      <b-button-toolbar justify>
        <div>
          <b-button
            v-if="(!BKDetail.IsArchive && (!BKDetail.IsPendingReview && !BKDetail.IsApproved && !BKDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.DeleteBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Delete in Database'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="btn-icon mb-1"
            @click="DeleteBooking()"
          >
            <font-awesome-icon
              :icon="['far', 'trash-alt']"
              size="lg"
            />
          </b-button>&nbsp;
          <b-button
            v-if="((Role != 'readonly' && Role != 'readonly admin' && Role != 'customer') && (!BKDetail.IsArchive)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Archive booking'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="ArchiveBooking()"
          ><font-awesome-icon
             :icon="['fas', 'archive']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Archive</span></b-button>&nbsp;
          <b-button
            v-if="((Role != 'readonly' && Role != 'readonly admin' && Role != 'customer') && (BKDetail.IsArchive)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Unarchive Booking'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="UnArchiveBooking()"
          ><font-awesome-icon
             :icon="['fas', 'archive']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Unarchive</span></b-button>&nbsp;

          <b-button
            v-if="((Role != 'readonly' && Role != 'readonly admin' && Role != 'customer')) || (Role == 'company admin')"
            v-b-tooltip.hover="'Duplicate'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="DuplicateBooking()"
          >
            <font-awesome-icon
              :icon="['fas', 'copy']"
              size="lg"
            /></b-button>&nbsp;

          <b-button
            v-b-tooltip.hover="'Print'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="Print()"
          >
            <font-awesome-icon
              :icon="['fas', 'print']"
              size="lg"
            />

          </b-button>&nbsp;
          <b-button
            v-if="((Role != 'readonly' && Role != 'readonly admin' && Role != 'customer')) || (Role == 'company admin')"
            v-b-tooltip.hover="'Email'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="OpenEmail()"
          >
            <font-awesome-icon
              :icon="['fas', 'envelope-open-text']"
              size="lg"
            />

          </b-button>&nbsp;
          <b-button
            v-b-tooltip.hover="'Refresh'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="btn-icon mb-1"
            @click="LoadAll()"
          >
            <font-awesome-icon
              :icon="['fas', 'sync']"
              size="lg"
            />
          </b-button>&nbsp;
        </div>
        <div>
          <b-button
            v-if="(!BKDetail.IsArchive && BKDetail.IsScheduleSelected && (!BKDetail.IsCancel && !BKDetail.IsPendingReview && !BKDetail.IsApproved && !BKDetail.IsApprovedPending && !BKDetail.IsApprovedConfirmed && !BKDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Submit Booking'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="SubmitBooking()"
          ><font-awesome-icon
             :icon="['far', 'paper-plane']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Submit</span></b-button>&nbsp;
          <b-button
            v-if="(!BKDetail.IsArchive && (BKDetail.IsPendingReview && !BKDetail.IsApproved && !BKDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Withdraw Booking'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="WithdrawBooking()"
          ><font-awesome-icon
             :icon="['fas', 'backspace']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Withdraw</span></b-button>&nbsp;
          <b-button
            v-if="(!BKDetail.IsArchive && (!BKDetail.IsCancel && (BKDetail.IsPendingReview || !BKDetail.IsApproved) && !BKDetail.IsApprovedPending && !BKDetail.IsApprovedConfirmed && !BKDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Cancel Booking'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mb-1"
            @click="CancelBooking()"
          ><font-awesome-icon
             :icon="['fas', 'lock']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Cancel</span></b-button>&nbsp;
          <b-button
            v-if="(!BKDetail.IsArchive && (BKDetail.IsCancel && !BKDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Cancel Booking'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mb-1"
            @click="ReopenBooking()"
          ><font-awesome-icon
             :icon="['fas', 'lock-open']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Reopen</span></b-button>&nbsp;
          <b-button
            v-if="(!BKDetail.IsArchive && BKDetail.IsScheduleApproved && (BKDetail.IsPendingReview && !BKDetail.IsApproved && !BKDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.ApproveBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Approve Booking'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-1"
            @click="ApproveBooking()"
          ><font-awesome-icon
             :icon="['far', 'check-circle']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Approve</span></b-button>&nbsp;

          <b-button
            v-if="(!BKDetail.IsArchive && BKDetail.IsScheduleApproved && !BKDetail.IsPendingReview && BKDetail.IsApproved && !BKDetail.IsAttached && !BKDetail.IsApprovedConfirmed && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Space Pending'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="warning"
            class="mb-1"
            @click="SpacePending()"
          ><font-awesome-icon
             :icon="['far', 'check-circle']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Space Pending</span></b-button>&nbsp;

          <b-button
            v-if="(!BKDetail.IsArchive && BKDetail.IsScheduleApproved && !BKDetail.IsPendingReview && !BKDetail.IsApproved && !BKDetail.IsAttached && BKDetail.IsApprovedPending && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Space Confirmed'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            class="mb-1"
            @click="SpaceConfirmed()"
          ><font-awesome-icon
             :icon="['far', 'check-circle']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Space Confirmed</span></b-button>&nbsp;

          <b-button
            v-if="(!BKDetail.IsArchive && (((!BKDetail.IsPendingReview && BKDetail.IsApproved) || (BKDetail.IsPendingReview && !BKDetail.IsApproved) ) && !BKDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.ApproveBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Deny Booking'"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="danger"
            class="mb-1"
            @click="DenyBooking()"
          ><font-awesome-icon
             :icon="['far', 'times-circle']"
             class="mr-50"
             size="lg"
           />
            <span class="align-middle">Deny</span></b-button>
        </div>
      </b-button-toolbar>
      <div class="divider my-2">
        <div class="divider-text">
          {{ 'Detail - Booking Date: ' + new Date(BKDetail.ReceivedDate).toLocaleDateString('en-US') + ' | Approval Date: ' + (CheckNull(BKDetail.ApprovedDate) ? new Date(BKDetail.ApprovedDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(BKDetail.ApprovedDate).toLocaleDateString('en-US') : 'N/A' : 'N/A') }}
        </div>
      </div>
      <b-row>
        <b-col md="12">
          <validation-observer
            ref="DetailRules"
            tag="form"
          >
            <b-button-toolbar justify>
              <div style="width:75%">
                <b-row>
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="HBL/Booking #"
                        rules="max:50|required"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>HBL/Booking #:</b></i></label>
                          <b-form-input
                            id="BookingNumber"
                            v-model="BKDetail.BookingNumber"
                            v-uppercase
                            :state="errors.length > 0 ? false:null"
                            placeholder="Booking #"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="4">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Customer Ref #"
                        rules="max:50"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label><i><b>Customer Ref #:</b></i></label>
                          <b-form-input
                            id="CustomerReference"
                            v-model="BKDetail.CustomerReference"
                            :state="errors.length > 0 ? false:null"
                            placeholder="Customer Ref #"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="3">
                    <b-form-group>
                      <validation-provider
                        #default="{ errors }"
                        name="Detention Day"
                        rules="required|min_value:0|positive"
                      >
                        <b-form-group
                          :state="errors.length > 0 ? false:null"
                        >
                          <label class="required"><i><b>Detention Day:</b></i></label>
                          <b-form-input
                            v-model="BKDetail.DetentionDay"
                            type="number"
                            :state="errors.length > 0 ? false:null"
                            placeholder="0"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col
                    md="1"
                  >
                    <label><i><b>DG:</b></i></label>
                    <b-form-checkbox v-model="BKDetail.IsDG" />
                  </b-col>

                </b-row>

              </div>
              <div>
                <b-button
                  v-if="(!BKDetail.IsArchive && (!BKDetail.IsCancel && !BKDetail.IsPendingReview && !BKDetail.IsApproved && !BKDetail.IsAttached) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
                  v-b-tooltip.hover="'Save'"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="btn-icon"
                  @click="UpdateDetail()"
                >
                  <font-awesome-icon
                    :icon="['far', 'save']"
                    size="lg"
                  />
                </b-button>
              </div>
            </b-button-toolbar>
            <b-row>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Booking Mode"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Booking Mode:</b></i></label>
                      <v-select
                        id="BookingMode"
                        v-model="BKDetail.BookingMode"
                        :options="BookingModeOptions"
                        label="text"
                        placeholder="Select a mode"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Service Type"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Service Type:</b></i></label>
                      <v-select
                        id="BookingType"
                        v-model="BKDetail.BookingType"
                        :options="BookingTypeOptions"
                        label="text"
                        placeholder="Select a type"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Service Mode"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Service Mode:</b></i></label>
                      <v-select
                        id="ServiceType"
                        v-model="BKDetail.ServiceType"
                        :options="ServiceTypeOptions"
                        label="text"
                        placeholder="Select a type"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Incoterm"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Incoterm:</b></i></label>
                      <v-select
                        id="Incoterm"
                        v-model="BKDetail.Incoterm"
                        :options="IncotermOptions"
                        label="text"
                        placeholder="Select a term"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Customer"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Customer:</b></i></label>
                      <v-select
                        id="CustomerOption"
                        v-model="BKDetail.CustomerId"
                        style="width:100%"
                        :options="CustomerOptions"
                        label="name"
                        placeholder="Select a Customer"
                        @input="onChangeSetConsigneeSeller"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Consignee"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Consignee:</b></i></label>
                      <v-select
                        id="ConsigneeOption"
                        v-model="BKDetail.ConsigneeId"
                        :options="ConsigneeOptions"
                        label="name"
                        placeholder="Select a Consignee"
                        :disabled="CheckNull(BKDetail.CustomerId) ? false : true"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Shipper"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Shipper:</b></i></label>
                      <v-select
                        id="SellerOption"
                        v-model="BKDetail.SellerId"
                        :options="SellerOptions"
                        label="name"
                        placeholder="Select a Shipper"
                        :disabled="CheckNull(BKDetail.CustomerId) ? false : true"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="PO #"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>PO #:</b></i></label>
                      <v-select
                        id="PONumber"
                        v-model="BKDetail.PONumber"
                        taggable
                        multiple
                        placeholder="PO #"
                        push-tags
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Cargo Ready Date"
                    rules="required"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label class="required"><i><b>Cargo Ready Date:</b></i></label>
                      <b-form-datepicker
                        v-model="BKDetail.CargoReadyDate"
                        placeholder="MM/DD/YYYY"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                        locale="en"
                        :reset-button="true"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="6">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Commodity"
                    rules="max:100"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Commodity:</b></i></label>
                      <b-form-input
                        id="Commodity"
                        v-model="BKDetail.Commodity"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Commodity"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Final Destination"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Final Destination:</b></i></label>
                      <v-select
                        v-model="BKDetail.FinalDest"
                        :options="POLPODOptions"
                        label="location"
                        placeholder="Select a Location"
                      />
                      <b-form-invalid-feedback :state="errors.length > 0 ? false:null">
                        {{ errors[0] }}
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group>
                  <validation-provider
                    #default="{ errors }"
                    name="Delivery Location"
                    rules="max:150"
                  >
                    <b-form-group
                      :state="errors.length > 0 ? false:null"
                    >
                      <label><i><b>Delivery Location:</b></i></label>
                      <b-form-input
                        id="DeliveryLocation"
                        v-model="BKDetail.DeliveryLocation"
                        v-uppercase
                        :state="errors.length > 0 ? false:null"
                        placeholder="Delivery Location"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </b-form-group>
                  </validation-provider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="2">
                <label class="required"><i><b>Total CBM:</b></i></label><br>
                <span>{{ BKDetail.CBM }}</span>
              </b-col>
              <b-col md="3">
                <label class="required"><i><b>Total Weight (KG):</b></i></label><br>
                <span>{{ BKDetail.Weight }}</span>
              </b-col>
              <b-col md="2">
                <label><i><b>Total Pallets:</b></i></label><br>
                <span>{{ BKDetail.Pallet }}</span>
              </b-col>
              <b-col md="3">
                <label><i><b>Total Cartons:</b></i></label><br>
                <span>{{ BKDetail.CTN }}</span>
              </b-col>
              <b-col md="2">
                <label><i><b>Total Pcs:</b></i></label><br>
                <span>{{ BKDetail.PCS }}</span>
              </b-col>
            </b-row>
          </validation-observer>
          <div class="divider my-2">
            <div class="divider-text">
              {{ 'Last Updated: ' + BKDetail.UpdatedOn + ' by ' + BKDetail.UpdatedBy + ' | Created: ' + BKDetail.CreatedOn + ' by ' + BKDetail.CreatedBy }}
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col md="12">
          <b-tabs lazy>
            <b-tab
              ref="ScheduleTab"
              title="Sailing Schedule"
            >
              <BookingDetailSchedule
                ref="BookingDetailSchedule"
                :booking-id="BookingId"
                @RefreshBooking="RefreshBooking"
              />
            </b-tab>
            <b-tab
              ref="ContainerTab"
              title="Containers"
            >
              <BookingDetailContainer
                :booking-id="BookingId"
                @LoadChildWeightCBM="LoadChildWeightCBM"
              />
            </b-tab>
            <b-tab
              ref="DocumentTab"
              title="Documents"
            >
              <BookingDetailDocuments
                :booking-id="BookingId"
              />
            </b-tab>
            <b-tab
              ref="TimelineTab"
              title="Timeline"
            >
              <BookingDetailTimeline
                :booking-id="BookingId"
              />
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
    </b-card>

    <b-modal
      id="DateHistoryModal"
      ref="DateHistory"
      title="Date History"
      size="lg"
      centered
      no-close-on-backdrop
    >
      <b-row>
        <b-table
          v-if="ResponseTable"
          :fields="fields"
          :items="ResponseTable"
          responsive=""
          hover
        />
      </b-row>
      <template #modal-footer>
        <div /></template>
    </b-modal>

    <BookingDetailEmail
      ref="BookingEmail"
      :booking-id="BookingId"
      @OpenEmail="OpenEmail"
    />
  </div>
</template>

<script>
import axios from '@axios'
import { required } from '@validations'
import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'
import BookingDetailSchedule from './BookingDetailSchedule.vue'
import BookingDetailContainer from './BookingDetailContainer.vue'
import BookingDetailTimeline from './BookingDetailTimeline.vue'
import BookingDetailDocuments from './BookingDetailDocuments.vue'
import BookingDetailEmail from './BookingDetailEmail.vue'

pdfMake.vfs = pdfFonts.pdfMake.vfs

export default {
  components: {
    BookingDetailSchedule,
    BookingDetailContainer,
    BookingDetailTimeline,
    BookingDetailDocuments,
    BookingDetailEmail,
  },
  data() {
    return {
      fields: [
        { key: 'dateType', lable: 'Date Type' },
        { key: 'originalDate', lable: 'Original Date' },
        { key: 'updatedDate', lable: 'New Date' },
        { key: 'updatedBy', lable: 'Updated By' },
        { key: 'updatedOn', lable: 'Updated On' },
      ],
      ResponseTable: [],

      required,
      BookingId: this.$route.params.bookingId,
      CustomerOptions: [],
      ConsigneeOptions: [],
      SellerOptions: [],
      BookingModeOptions: [
        { text: 'Air', value: 'Air' },
        { text: 'FCL', value: 'FCL' },
        { text: 'LCL', value: 'LCL' },
        { text: 'BCN', value: 'BCN' },
      ],
      BookingTypeOptions: [
        { text: 'FIX', value: 'FIX' },
        { text: 'SPOT', value: 'SPOT' },
        { text: 'EXX', value: 'EXX' },
        { text: 'SOC', value: 'SOC' },
        { text: 'PREMIUM', value: 'PREMIUM' },
        { text: 'LAND & SEA', value: 'LAND & SEA' },
        { text: 'AIR & SEA', value: 'AIR & SEA' },
      ],
      ServiceTypeOptions: [
        { text: 'CY/CY', value: 'CY/CY' },
        { text: 'CY/DOOR', value: 'CY/DOOR' },
        { text: 'CFS/CFS', value: 'CFS/CFS' },
        { text: 'CFS/DOOR', value: 'CFS/DOOR' },
        { text: 'CFS/CY', value: 'CFS/CY' },
        { text: 'CY/RAMP', value: 'CY/RAMP' },
      ],
      IncotermOptions: [
        { text: 'EXW', value: 'EXW' },
        { text: 'FCA', value: 'FCA' },
        { text: 'FAS', value: 'FAS' },
        { text: 'FOB', value: 'FOB' },
        { text: 'CFR', value: 'CFR' },
        { text: 'CIF', value: 'CIF' },
        { text: 'CPT', value: 'CPT' },
        { text: 'CIP', value: 'CIP' },
        { text: 'DAP', value: 'DAP' },
        { text: 'DAU', value: 'DAU' },
        { text: 'DDP', value: 'DDP' },
        { text: 'DDU', value: 'DDU' },
      ],
      BKDetail: {
        CustomerId: '',
        Seller: '',
        SellerId: '',
        Consignee: '',
        ConsigneeId: '',
        BookingMode: '',
        BookingNumber: '',
        CustomerReference: '',
        BookingType: '',
        Incoterm: '',
        IsNew: false,
        IsCancel: false,
        IsReopen: false,
        IsPendingReview: false,
        IsApproved: false,
        IsApprovedPending: false,
        IsApprovedConfirmed: false,
        IsDenied: false,
        IsAttached: false,
        IsArchive: false,
        IsDG: false,
        IsScheduleApproved: false,
        IsScheduleSelected: false,
        Commodity: '',
        Pallet: 0,
        ReceivedDate: '',
        ApprovedDate: '',
        PONumber: '',
        POL: 0,
        POD: 0,
        FinalDest: 0,
        ServiceType: '',
        CBM: '',
        Weight: '',
        PCS: 0,
        CTN: 0,
        Carrier: '',
        CarrierId: 0,
        DeliveryLocation: '',
        CargoReadyDate: '',
        DetentionDay: 0,
        UpdatedBy: '',
        UpdatedOn: '',
        CreatedBy: '',
        CreatedOn: '',
      },
      minETDDate: '',
      minATDDate: '',
      minETAFirstPort: '',
      minATAFirstPort: '',
      Dates: {
        BookingETD: '',
        CargoReadyDate: '',
        ETAFirstPort: '',
        ETAFinalDest: '',
        LastUpdatedOn: '',
        LastUpdatedBy: '',
      },
      CarrierOptions: [],
      POLPODOptions: [],
      Permission: {},
      Role: '',
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.LoadAll()
  },
  methods: {
    LoadAll() {
      Promise.all([this.LoadingStart(), this.LoadCustomerList(), this.LoadPOLPODList(), this.LoadCarrierList()]).then(() => {
        this.LoadBookingDetail().then(() => {
          Promise.all([this.LoadSellerList(), this.LoadConsigneeList()]).then(() => {
            this.BKDetail.SellerId = this.SellerOptions.find(option => option.id === this.BKDetail.Seller)
            this.BKDetail.ConsigneeId = this.ConsigneeOptions.find(option => option.id === this.BKDetail.Consignee)
            this.BKDetail.Carrier = this.CarrierOptions.find(option => option.id === this.BKDetail.CarrierId)
          })
        })
      }).then(() => {
        this.LoadingEnd()
      })
    },
    LoadCarrierList() {
      return axios.get('/booking/carrier')
        .then(rowData => {
          this.CarrierOptions = rowData.data
        })
    },
    LoadPOLPODList() {
      // Loading Customer List
      return axios.get('/booking/codes/polpod')
        .then(rowData => {
          this.POLPODOptions = rowData.data
        })
    },
    LoadCustomerList() {
      // Loading Customer List
      return axios.get('/booking/customer/codes/all')
        .then(rowData => {
          this.CustomerOptions = rowData.data
        })
    },
    LoadSellerList() {
      // Loading Customer List
      return axios.get(`/booking/customer/codes/${this.BKDetail.CustomerId.id}/seller`)
        .then(rowData => {
          this.SellerOptions = rowData.data
        })
    },
    LoadConsigneeList() {
      // Loading Customer List
      return axios.get(`/booking/customer/codes/${this.BKDetail.CustomerId.id}/consignee`)
        .then(rowData => {
          this.ConsigneeOptions = rowData.data
        })
    },
    LoadBookingDetail() {
      return axios.get(`/booking/${this.BookingId}/detail`)
        .then(resp => {
          const response = resp.data
          const respdata = response

          this.BKDetail.IsNew = respdata.isNew
          this.BKDetail.IsCancel = respdata.isCancel
          this.BKDetail.IsReopen = respdata.isReopen
          this.BKDetail.IsPendingReview = respdata.isPendingReview
          this.BKDetail.IsApproved = respdata.isApproved
          this.BKDetail.IsApprovedPending = respdata.isApprovedPending
          this.BKDetail.IsApprovedConfirmed = respdata.isApprovedConfirmed
          this.BKDetail.IsDenied = respdata.isDenied
          this.BKDetail.IsAttached = respdata.isAttached
          this.BKDetail.IsArchive = respdata.isArchive
          this.BKDetail.IsDG = respdata.isDG
          this.BKDetail.IsScheduleApproved = respdata.isScheduleApproved
          this.BKDetail.IsScheduleSelected = respdata.isScheduleSelected
          this.BKDetail.CargoReadyDate = new Date(respdata.cargoReadyDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.cargoReadyDate) : null
          this.BKDetail.DetentionDay = respdata.detentionDay
          this.BKDetail.BookingNumber = respdata.bookingNumber
          this.BKDetail.CustomerReference = respdata.customerReference
          this.BKDetail.BookingMode = this.BookingModeOptions.find(option => option.value === respdata.bookingMode)
          this.BKDetail.BookingType = this.BookingTypeOptions.find(option => option.value === respdata.bookingType)
          this.BKDetail.ServiceType = this.ServiceTypeOptions.find(option => option.value === respdata.serviceType)
          this.BKDetail.Incoterm = this.IncotermOptions.find(option => option.value === respdata.incoterm)
          try {
            if (this.CheckNull(respdata.poNumber)) this.BKDetail.PONumber = respdata.poNumber.split(',')
            else this.BKDetail.PONumber = ''
          } catch (e) { this.BKDetail.PONumber = '' }

          this.BKDetail.CustomerId = this.CustomerOptions.find(option => option.id === respdata.customerId)
          this.BKDetail.Seller = respdata.sellerId
          this.BKDetail.Consignee = respdata.consigneeId

          this.BKDetail.ReceivedDate = new Date(respdata.receivedDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.receivedDate) : null
          this.BKDetail.ApprovedDate = new Date(respdata.approvedDate).toJSON().split('T')[0] !== '1970-01-01' ? new Date(respdata.approvedDate) : null

          this.BKDetail.Commodity = respdata.commodity

          this.BKDetail.CarrierId = respdata.carrier
          this.BKDetail.POL = this.POLPODOptions.find(option => option.id === respdata.pol)
          this.BKDetail.POD = this.POLPODOptions.find(option => option.id === respdata.pod)

          this.BKDetail.FinalDest = this.POLPODOptions.find(option => option.id === respdata.finalDest)
          this.BKDetail.DeliveryLocation = respdata.deliveryLocation

          this.BKDetail.CBM = respdata.cbm
          this.BKDetail.Weight = respdata.weight
          this.BKDetail.Pallet = respdata.pallet
          this.BKDetail.PCS = respdata.pcs
          this.BKDetail.CTN = respdata.ctn

          this.BKDetail.CreatedBy = respdata.createdBy
          this.BKDetail.CreatedOn = respdata.createdOn
          this.BKDetail.UpdatedBy = respdata.updatedBy
          this.BKDetail.UpdatedOn = respdata.updatedOn
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
        })
    },
    RefreshBooking() {
      this.LoadBookingDetail()
    },
    DeleteBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, delete it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.delete(`/booking/${this.BookingId}`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been removed.')
              .then(() => {
                // redirect
                this.$router.push('/booking')
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot remove booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    DuplicateBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, duplicate it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.post(`/booking/${this.BookingId}/duplicate`).then(response => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been duplicated.')
              .then(() => {
                // redirect
                window.location.href = `/booking/detail/${response.data}`
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot duplicate booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    SubmitBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, submit it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()

          axios.put(`/booking/${this.BookingId}/submit`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been submitted.')
              .then(() => {
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot submit booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    WithdrawBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, withdraw it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/${this.BookingId}/withdraw`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been withdrawn.')
              .then(() => {
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot withdraw booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ApproveBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, apporve it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()

          axios.put(`/booking/${this.BookingId}/approve`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been approved.')
              .then(() => {
                this.$refs.BookingDetailSchedule.LoadScheduleList()
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot approve booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    SpacePending() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Space is pending!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()

          axios.put(`/booking/${this.BookingId}/approve/space/pending`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Status changed to space pending.')
              .then(() => {
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot set status to space pending.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    SpaceConfirmed() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Space is confirmed!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()

          axios.put(`/booking/${this.BookingId}/approve/space/confirmed`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Status changed to space confirmed.')
              .then(() => {
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot set status to space confirmed.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    DenyBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, deny it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()

          axios.put(`/booking/${this.BookingId}/deny`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been denied.')
              .then(() => {
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot deny booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    CancelBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, cancel it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()

          axios.put(`/booking/${this.BookingId}/cancel`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been cancelled.')
              .then(() => {
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot cancel booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ReopenBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, reopen it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()

          axios.put(`/booking/${this.BookingId}/reopen`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been reopened.')
              .then(() => {
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot reopen booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    ArchiveBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, archive it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/${this.BookingId}/archive`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been archived.')
              .then(() => {
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot archive booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    UnArchiveBooking() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, unarchive it!',
      }).then(result => {
        if (result.isConfirmed) {
          this.LoadingStart()
          axios.put(`/booking/${this.BookingId}/unarchive`).then(() => {
            this.LoadingEnd()
            this.DisplaySuccess('Booking has been unarchived.')
              .then(() => {
                this.LoadBookingDetail()
              })
          }).catch(e => {
            this.LoadingEnd()
            this.DisplayErrorRemove('Cannot unarchive booking.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
          })
        }
      })
    },
    UpdateDetail() {
      this.$refs.DetailRules.validate().then(result => {
        if (result) {
          this.LoadingStart()
          let POJoin = ''
          try {
            POJoin = this.BKDetail.PONumber.join()
          } catch (e) { POJoin = '' }
          const BookingModel = {
            CustomerId: this.BKDetail.CustomerId.id,
            ConsigneeId: this.CheckNull(this.BKDetail.ConsigneeId) ? this.BKDetail.ConsigneeId.id : null,
            SellerId: this.CheckNull(this.BKDetail.SellerId) ? this.BKDetail.SellerId.id : null,
            BookingNumber: this.BKDetail.BookingNumber,
            CustomerReference: this.BKDetail.CustomerReference,
            BookingMode: this.BKDetail.BookingMode.value,
            BookingType: this.BKDetail.BookingType.value,
            Incoterm: this.BKDetail.Incoterm.value,
            CargoReadyDate: new Date(this.BKDetail.CargoReadyDate).toJSON().split('T')[0],
            DetentionDay: this.BKDetail.DetentionDay,
            IsDG: this.BKDetail.IsDG,
            Commodity: this.BKDetail.Commodity,
            PONumber: POJoin,
            // POL: this.CheckNull(this.BKDetail.POL) ? this.BKDetail.POL.id : null,
            // POD: this.CheckNull(this.BKDetail.POD) ? this.BKDetail.POD.id : null,
            FinalDest: this.CheckNull(this.BKDetail.FinalDest) ? this.BKDetail.FinalDest.id : null,
            ServiceType: this.BKDetail.ServiceType.value,
            CBM: this.BKDetail.CBM,
            Weight: this.BKDetail.Weight,
            // Carrier: this.CheckNull(this.BKDetail.Carrier) ? this.BKDetail.Carrier.id : null,
            DeliveryLocation: this.BKDetail.DeliveryLocation,
          }
          // Post
          axios.put(`/booking/${this.BookingId}/detail`, BookingModel)
            .then(() => {
              this.LoadingEnd()
              this.DisplaySuccess('Details have been saved.').then(() => {
                this.LoadBookingDetail()
              })
            })
            .catch(e => {
              this.LoadingEnd()
              this.DisplayError('Details cannot be saved.', `${e.response.data.errorCode} / ${e.response.data.errorMessage}`)
            })
        } else {
          this.DisplayError('Details cannot be saved.', 'Please check data requirements.')
        }
      })
    },
    onChangeSetConsigneeSeller() {
      if (this.CheckNull(this.BKDetail.CustomerId)) {
        this.BKDetail.SellerId = ''
        this.BKDetail.ConsigneeId = ''
        this.LoadSellerList()
        this.LoadConsigneeList()
      }
    },
    OpenEmail() {
      this.$refs.BookingEmail.OpenEmail()
    },
    LoadPDF() {
      const dd = {
        pageOrientation: 'portrait',
        pageSize: 'LETTER',
        content: [],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 10, 0, 5],
          },
          table: {
            margin: [0, 5, 0, 15],
          },
          tableHeader: {
            bold: true,
            fontSize: 11,
            color: 'black',
          },
          tbtitle: {
            bold: true,
            italics: true,
            fontSize: 11,
            color: 'black',
          },
        },
        defaultStyle: {
          // alignment: 'justify'
        },
      }
      const BookingDetail = [
        { text: 'Booking Detail', style: 'subheader' },
        {
          style: 'table',
          color: '#444',
          table: {
            widths: ['auto', 'auto'],
            body: [
              [{ text: 'Booking #:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.BookingNumber}` }],
              [{ text: 'Customer Reference #:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.CustomerReference || ''}` }],
              [{ text: 'Customer:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.CustomerId.name || ''}` }],
              [{ text: 'Service Type:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.BookingType.value || ''}` }],
              [{ text: 'Service Mode:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.ServiceType.value || ''}` }],
              [{ text: 'DG:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.IsDG ? 'Yes' : 'No' || 'No'}` }],
              [{ text: 'PO:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.PONumber || ''}` }],
              [{ text: 'Commodity:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.Commodity || ''}` }],
              [{ text: 'Preferred Carrier:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.Carrier || ''}` }],
              [{ text: 'Port of Loading:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.POL.location || ''}` }],
              [{ text: 'Port of Discharge:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.POD.location || ''}` }],
              [{ text: 'Destination:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.FinalDest || ''}` }],
              [{ text: 'Total CBM:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.CBM || ''}` }],
              [{ text: 'Total Weight (KG):', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.Weight || ''}` }],
              [{ text: 'Total Pallets:', style: 'tbtitle', alignment: 'center' }, { text: `${this.BKDetail.Pallet || ''}` }],
            ],
          },
        },
        // Routing
        {
          style: 'table',
          color: '#444',
          table: {
            widths: ['auto', 'auto'],
            body: [
              [{ text: 'Cargo Ready Date:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.Dates.CargoReadyDate).toJSON().split('T')[0] || ''}` }],
              [{ text: 'Detention Days:', style: 'tbtitle', alignment: 'center' }, { text: '' }],
              [{ text: 'ETD:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.Dates.BookingETD).toJSON().split('T')[0] || ''}` }],
              [{ text: 'ETA First US Port:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.Dates.ETAFirstPort).toJSON().split('T')[0] || ''}` }],
              [{ text: 'ETA Final Destination:', style: 'tbtitle', alignment: 'center' }, { text: `${new Date(this.Dates.ETAFinalDest).toJSON().split('T')[0] || ''}` }],
            ],
          },
        },
        // Conatiners
      ]

      this.Containers.forEach(c => {
        const container = [
          {
            style: 'table',
            color: '#444',
            table: {
              widths: ['auto', 'auto'],
              body: [
                [{ text: 'Container Size:', style: 'tbtitle', alignment: 'center' }, { text: `${c.containerSize}` }],
                [{ text: '# of Containers:', style: 'tbtitle', alignment: 'center' }, { text: `${c.qty}` }],
                [{ text: 'CBM:', style: 'tbtitle', alignment: 'center' }, { text: `${c.cbm}` }],
                [{ text: 'Weight (KG):', style: 'tbtitle', alignment: 'center' }, { text: `${c.weight}` }],
                [{ text: 'Pallet:', style: 'tbtitle', alignment: 'center' }, { text: `${c.pallet}` }],
                [{ text: 'PCS:', style: 'tbtitle', alignment: 'center' }, { text: `${c.pcs}` }],
                [{ text: 'Sell Rate:', style: 'tbtitle', alignment: 'center' }, { text: `${c.oceanFreightSellRate}` }],
                [{ text: 'Overweight?:', style: 'tbtitle', alignment: 'center' }, { text: `${(c.qty * c.containerSize.weightLimit < c.Weight * 2.20462) ? 'Yes' : 'No'}` }],
              ],
            },
          },
        ]
        BookingDetail.push(container)
      })

      dd.content.push(BookingDetail)

      return dd
    },
    Print() {
      const doc = this.LoadPDF()
      const win = window.open('', '_blank')
      pdfMake.createPdf(doc).open({}, win)
    },
    LoadChildWeightCBM(v) {
      this.BKDetail.CBM = v.CBM
      this.BKDetail.Weight = v.Weight
      this.BKDetail.Pallet = v.Pallet
      this.BKDetail.PCS = v.PCS
      this.BKDetail.CTN = v.CTN
    },
  },
}
</script>
