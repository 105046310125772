import Vue from 'vue'
import axios from '@axios'

export default Vue.extend({
  data() {
    return {
      Role: '',
      Permission: [],
      BKDetail: {
        IsNew: false,
        IsCancel: false,
        IsReopen: false,
        IsPendingReview: false,
        IsApproved: false,
        IsApprovedPending: false,
        IsApprovedConfirmed: false,
        IsDenied: false,
        IsAttached: false,
        IsArchive: false,
      },
    }
  },
  beforeMount() {
    axios.defaults.headers.common.DBContext = this.$store.state.user.AppActiveUser.BookingDbContext
    this.UserPermission()
  },
  mounted() { },
  methods: {
    LoadBookingDetail() {
      axios.get(`/booking/${this.params.data.bookingId}/detail`)
        .then(resp => {
          const response = resp.data
          const respdata = response

          this.BKDetail.IsNew = respdata.isNew
          this.BKDetail.IsCancel = respdata.isCancel
          this.BKDetail.IsReopen = respdata.isReopen
          this.BKDetail.IsPendingReview = respdata.isPendingReview
          this.BKDetail.IsApproved = respdata.isApproved
          this.BKDetail.IsApprovedPending = respdata.isApprovedPending
          this.BKDetail.IsApprovedConfirmed = respdata.isApprovedConfirmed
          this.BKDetail.IsDenied = respdata.isDenied
          this.BKDetail.IsAttached = respdata.isAttached
          this.BKDetail.IsArchive = respdata.isArchive
          // Get user role
          this.Role = this.GetUserRole()
          // Get permission
          this.Permission = this.GetUserPermission('Booking')
        })
    },
    UserPermission() {
      this.LoadBookingDetail()
    },
    Clickhandler() {
      let returnID = ''
      if (this.params.data.id) returnID = this.params.data.id

      this.params.context.componentParent.Open(returnID)
    },
    Deletehandler() {
      let returnID = ''
      if (this.params.data.id) returnID = this.params.data.id

      this.params.context.componentParent.Delete(returnID)
    },
  },
  template: `
        <span>
            <b-button
            v-b-tooltip.hover="'View'"
            size="sm" 
            @click="Clickhandler()" 
            variant="primary" 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            >
            <font-awesome-icon
              :icon="['fas', 'eye']"
              size="lg"
            />
            </b-button>
            <b-button
            v-if="(!BKDetail.IsArchive && (!BKDetail.IsCancel && !BKDetail.IsPendingReview && !BKDetail.IsApproved && !BKDetail.IsAttached) && (!BKDetail.IsApprovedPending && !BKDetail.IsApprovedConfirmed) && (Role != 'readonly' && Role != 'readonly admin') && (Permission.EditBooking)) || (Role == 'company admin')"
            v-b-tooltip.hover="'Delete'"
            size="sm" 
            @click="Deletehandler()" 
            variant="danger" 
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="btn-icon"
            >
            <font-awesome-icon
              :icon="['fas', 'trash-alt']"
              size="lg"
            />
            </b-button>
        </span>
    `,
})
